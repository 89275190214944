import styled from "styled-components";

const Photo = styled.img`
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
`;

export default Photo;
