import React from "react";
import { useTheme } from "styled-components";

const ArrowFull = ({ direction, color }) => {
  const theme = useTheme();
  const fill = color || theme.colors.default;

  let rotation;
  switch (direction) {
    case "up":
      rotation = 180;
      break;
    case "right":
      rotation = 270;
      break;
    case "down":
      rotation = 0;
      break;
    case "left":
      rotation = 90;
      break;
    default:
      rotation = 0;
  }

  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotation}deg)` }}
    >
      <path
        d="M12.0258 8.1375L10.8008 6.9125L7.03828 10.675V0H5.28828V10.675L1.52578 6.9125L0.300781 8.1375L6.16328 14L12.0258 8.1375Z"
        fill={fill}
      />
    </svg>
  );
};
export default ArrowFull;
