import React from "react";
import { useTheme } from "styled-components";

const CloseX = ({ color }) => {
  const theme = useTheme();
  const fill = color || theme.colors.default;
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.59424 1.2334L7.7666 0.405762L4.61719 3.55518L1.46777 0.405762L0.640137 1.2334L3.78955 4.38281L0.640137 7.53223L1.46777 8.35986L4.61719 5.21045L7.7666 8.35986L8.59424 7.53223L5.44482 4.38281L8.59424 1.2334Z"
        fill={fill}
      />
    </svg>
  );
};

export default CloseX;
