import { useState, useRef, useLayoutEffect } from "react";

export const useRect = () => {
  const ref = useRef();
  const [bbox, setBbox] = useState({});

  useLayoutEffect(() => {
    const set = () => {
      setBbox(ref && ref.current ? ref.current.getBoundingClientRect() : {});
    };

    setTimeout(set, 500); // Delay first call so it doesn't return empty
    window.addEventListener("resize", set);
    return () => window.removeEventListener("resize", set);
  }, []);

  return [bbox, ref];
};

export default useRect;
